<!-- eslint-disable prettier/prettier -->
<template>
  <!-- Tariff area -->
  <!--  Bill -->
  <v-row class="mx-0 mt-6 mb-0 px-0 py-0">
    <v-col class="pt-0">
      <v-row>
        <div v-if="this.historic_data.length == 0">
          <v-col cols="12" class="text-left header header-3 pl-0 pt-2 pb-2">
            Inserir os dados da 1ª Fatura
          </v-col>
          <v-col cols="12" class="pt-3 pl-0">
            Insira os dados da fatura de energia do seu condomínio. Tenha também o
            contrato do condomínio com o seu comercializador de energia por perto
            para poder preencher este formulário.
          </v-col>
        </div>
        <div v-else>
          <v-col cols="12" class="text-left header header-3 pl-0 pt-2 pb-2">
            Definir Novo Período de Faturação
          </v-col>
          <v-col cols="12" class="pt-3 pl-0"
            >Insira aqui as datas de início e de fim da última fatura de energia
            do condomínio que recebeu e atualize as tarifas, caso estas tenham
            sofrido alterações.
          </v-col>
        </div>

        <v-row class="px-0 mx-0">
          <v-col
            style="font-size: 16px"
            class="font-weight-medium px-0 pb-1"
            cols="12"
            >Defina a data de início e de fim do último período de
            faturação</v-col
          >
          <v-col class="pl-0 pb-7" cols="6"
            ><v-row class="px-0 pb-0 mx-0">
              <v-col class="px-0 pt-0 pb-0 mx-0" cols="12"
                >Data de Início</v-col
              >

              <v-col class="pb-0 px-0 mx-0" cols="12"
                ><form-textbox
                  :field_key="'bp_start_date'"
                  :period="0"
                  :field_type="'date'"
                  :icon="'mdi-calendar-blank'"
                  :placeholder="'dd-mm-yyyy'"
                  :bold="true"
                  :title_alone="true"
                  :isDate="true"
                  @checkMethod="checkSubmitButton"
              /></v-col> </v-row
          ></v-col>
          <v-col class="pr-0 pb-7 mx-0" cols="6"
            ><v-row class="px-0 pb-0 mx-0">
              <v-col class="px-0 pt-0 pb-0 mx-0" cols="12">Data de Fim</v-col>
              <v-col class="px-0 pb-0 mx-0" cols="12"
                ><form-textbox
                  :field_key="'bp_end_date'"
                  :period="0"
                  :field_type="'date'"
                  :icon="'mdi-calendar-blank'"
                  :placeholder="'dd-mm-yyyy'"
                  :bold="true"
                  :title_alone="true"
                  :isDate="true"
                  @checkMethod="checkSubmitButton"
              /></v-col> </v-row
          ></v-col>

          <div>
            <v-row class="px-0 mx-0">
              <v-col cols="12" class="px-0 pt-0 pb-0">
                <tariff-data :checkSubmitButton="checkSubmitButton" />
              </v-col>
              <v-col
                cols="12"
                class="pt-0 px-0"
                v-if="this.contract_data[0].plan == 0"
              >
                <form-radio
                  :field_type="'string'"
                  :period="0"
                  :field_key="'schedule_type'"
                  :title="'Selecione os períodos horários de entrega de energia elétrica'"
                  :items="[
                    { value: '0', text: 'Simples' },
                    { value: '1', text: 'Bi-Horário' },
                    { value: '2', text: 'Tri-Horário' },
                  ]"
                  @checkMethod="checkSubmitButton"
                />
              </v-col>

              <v-col cols="12" class="px-0 pt-0 text text-5 normal-label">
                <span
                  >Indique o número de períodos de faturação existentes na
                  fatura.</span
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="tooltip">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="info"
                        class="info-icon"
                      >
                        mdi-information-outline
                      </v-icon>
                      <div style="padding-right: 280px">
                        <span class="tooltiptext tooltip-bottom"
                          >Verifique a sua fatura (campos relacionados com
                          "consumo") se existem sub-períodos. A soma corresponde
                          ao intervalo temporal definido no período de
                          faturação.</span
                        >
                      </div>
                    </div>
                  </template>
                </v-tooltip>
              </v-col>

              <v-col cols="12" class="d-flex justify-start px-0 pt-0"
                ><button
                  :disabled="this.numberTariffs == 1"
                  @click="decrementTariff"
                  class="sign d-flex align-center justify-center mr-8"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="3"
                    class="minus-sign"
                    viewBox="0 0 21 3"
                  >
                    <path
                      data-name="Minus Sign"
                      transform="translate(1.5 1.5)"
                      style="
                        fill: none;
                        stroke-linecap: round;
                        stroke-width: 3px;
                      "
                      d="M18 0H0"
                    />
                  </svg>
                </button>
                <div class="d-flex align-center">{{ this.numberTariffs }}</div>
                <button
                  @click="incrementTariff"
                  class="sign d-flex align-center justify-center ml-8"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                  >
                    <g data-name="Plus Sign">
                      <path
                        data-name="Linha 85"
                        transform="translate(10.5 1.5)"
                        style="
                          fill: none;
                          stroke: #fff;
                          stroke-linecap: round;
                          stroke-width: 3px;
                        "
                        d="M0 0v18"
                      />
                      <path
                        data-name="Linha 86"
                        transform="translate(1.5 10.5)"
                        style="
                          fill: none;
                          stroke: #fff;
                          stroke-linecap: round;
                          stroke-width: 3px;
                        "
                        d="M18 0H0"
                      />
                    </g>
                  </svg>
                </button>
              </v-col>
              <v-col
                cols="12"
                class="px-0 pt-0"
                v-for="n in this.numberTariffs"
                :key="n"
              >
                <v-row class="px-0 py-1">
                  <v-col
                    class="mr-auto d-flex align-center header-3 pl-3 px-0 font-weight-bold"
                    >Sub-Período {{ n }}
                    <img
                      v-if="valid == false && post == true"
                      style="width: 28px"
                      class="pl-2"
                      :src="require('@/assets/icons/warning-121.svg')"
                  /></v-col>

                  <v-col class="d-flex justify-end px-0"
                    ><v-icon
                      size="40"
                      @click="toggleRotation(n, true)"
                      color="black"
                      :class="
                        isRotated && n == catSelected ? 'rotated' : 'revert'
                      "
                      >mdi-chevron-right
                    </v-icon></v-col
                  >
                </v-row>
                <div v-if="catSelected == n && isRotated">
                  <v-form ref="form" v-model="valid">
                    <v-row
                      class="px-0 py-0 pb-3"
                      v-if="catSelected == n && isRotated"
                    >
                      <v-col cols="6"
                        ><form-textbox
                          :field_key="'start_date'"
                          :field_type="'date'"
                          :icon="'mdi-calendar-blank'"
                          :placeholder="'dd-mm-yyyy'"
                          :bold="true"
                          :title="'Data de Início'"
                          :isDate="true"
                      /></v-col>
                      <v-col cols="6"
                        ><form-textbox
                          :field_key="'end_date'"
                          :field_type="'date'"
                          :placeholder="'dd-mm-yyyy'"
                          :icon="'mdi-calendar-blank'"
                          :bold="true"
                          :title="'Data de Fim'"
                          :isDate="true"
                      /></v-col>
                    </v-row>

                    <fill-tariffs
                      :checkMargins="true"
                      :error="error"
                      @warning="checkWarning"
                      :noMargins="true"
                      :checkSubmitButton="checkSubmitButton"
                    />
                  </v-form>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-col cols="12" class="pt-6 pb-5 px-0">
            <submit-button
              :title="`Definir Novo Período`"
              :name="'update-tariff-info'"
              :disabled="this.buttonDisabled"
              @callEvent="addNewTariff"
            />
          </v-col>
        </v-row>
        <success-modal
          :modalName="'update-tariff-info'"
          :alt="'tariff success'"
          :icon="'icon-new-tariff.png'"
          :header="'Novo Período de Faturação Definido'"
          :text="'As datas e as tarifas do último período de faturação foram aplicados com sucesso.'"
          @callEvent="getContractData()"
        />
        <div class="section" v-if="this.historic_data.length != 0">
          <v-col cols="12" class="text-left header header-3 pl-0 pt-2 pb-3">
            Faturas Disponíveis
          </v-col>
          <v-col cols="12">
            <historic-bill :checkSubmit="checkSubmitButton" :disableButton="this.buttonDisabled" />
          </v-col>
        </div>
      </v-row>
      <tariff-modal
        :header="'Tarifas da Última Fatura'"
        :modalName="'tariff-modal'"
        :index="0"
        :historic="false"
      />
    </v-col>
  </v-row>
  <!-- End First Bill -->
  <!-- Start After first bill -->

  <!-- End Start After first bill -->
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import TariffSummary from "@/components/dashboard/tariff-summary.vue";
import TariffCycle from "@/components/dashboard/tariff-cycle.vue";
import FormSelect from "@/components/forms/form-select.vue";
import FormTextbox from "@/components/forms/form-textbox.vue";
import FormRadio from "@/components/forms/form-radio.vue";
import SubmitButton from "@/components/dashboard/submit-button.vue";
import SuccessModal from "@/components/general/success-modal.vue";
import PeriodRange from "@/components/dashboard/period-range.vue";
import FillTariffs from "@/components/dashboard/fill-tariffs.vue";
import BillNotification from "@/components/dashboard/bill-notification.vue";
import HistoricBill from "@/components/dashboard/historic-bill.vue";
import TariffModal from "@/components/general/tariff-modal.vue";
import TariffData from "@/components/dashboard/tariff-data.vue";
import { cloneDeep } from "lodash";
import config from "@/config";

import { isNull } from "lodash";
export default mixins(General).extend({
  name: "Tariff",
  components: {
    TariffSummary,
    TariffCycle,
    FormSelect,
    FormTextbox,
    FormRadio,
    SubmitButton,
    SuccessModal,
    PeriodRange,
    FillTariffs,
    BillNotification,
    HistoricBill,
    TariffModal,
    TariffData,
  },
  data() {
    return {
      error_button: "error-button",
      post: false,
      catSelected: 0,
      isRotated: false,
      show_section_1: false,
      valid: false,
      warning: false,
      error: false,
      show_section_2: false,
      dates_change: false,
      btn_clicked: "",
      tariffs: {
        tariff1: {
          header: "Tarifas do Período 1",
          subText: "Insira os dados das tarifas antes da mudança",
        },
        tariff2: {
          header: "Tarifas do Período 2",
          subText: "Insira os dados das tarifas depois da mudança",
        },
      },
    };
  },
  created() {
    if (this.condos_response.selected_role != "user") {
      this.$store
        .dispatch("getContractData", { uri: "bill_data" })
        .then((res: any) => {
          this.checkSubmitButton();
        });
    }
  },
  computed: {
    days_of_the_month() {
      return [...Array(31).keys()].map((num) => {
        return num + 1;
      });
    },

    numberTariffs: {
      get(): number {
        return this.$store.getters["numberTariffs"];
      },
      set(value: number) {
        this.$store.commit("setNumberTariffs", value);
      },
    },
  },
  methods: {
    isNullOrBlank(value: any) {
      return value == null || value === "";
    },

    checkData() {
      if (
        Object.keys(this.getEmptyProperties(this.contract_data[0]).length != 0)
      ) {
        this.error = true;
      } else this.error = false;
    },

    checkWarning(val: any) {
      this.warning = val;
    },

    incrementTariff(): void {
      this.$store.commit("setNumberTariffs", 1);
      this.$store.commit("addNewPeriod");
      this.checkSubmitButton();
    },

    decrementTariff(): void {
      this.$store.commit("setNumberTariffs", -1);
      this.$store.commit("removePeriod");
      this.checkSubmitButton();
    },

    toggleRotation(index: any, status: boolean): any {
      this.$store.commit("updatePeriod", index - 1);
      //For if the previous state is rotated and we select another we need to be able to close that one
      if (this.isRotated == true && this.catSelected == index) {
        this.isRotated = false;
      } else {
        this.catSelected = index;
        this.isRotated = status;
      }
    },

    proc(): void {
      this.checkDates();
      this.checkSubmitButton();
    },
    checkDates(): void {
      this.dates_change = this.contract_data[0].tariffs_change;
    },
    btnClick(i: any): void {
      if (i == this.btn_clicked) this.btn_clicked = "";
      else this.btn_clicked = i;
      if (this.btn_clicked == "tariff1") {
        this.$store.commit("updatePeriod", 0);
      } else if (this.btn_clicked == "tariff2") {
        this.$store.commit("updatePeriod", 1);
      } else {
        this.$store.commit("updatePeriod", 0);
      }
    },

    checkShowSection1(): void {
      this.show_section_1 =
        this.contract_data[this.contract_data.length - 1].cycle === null ||
        this.contract_data[this.contract_data.length - 1].plan === null ||
        this.contract_data[this.contract_data.length - 1].contracted_power ==
          "" ||
        this.contract_data[this.contract_data.length - 1].contracted_power ==
          null
          ? false
          : true;
    },
    checkShowSection2(): void {
      this.checkShowSection1();
      this.show_section_2 = true;
      if (
        !this.show_section_1 ||
        !this.contract_data[this.contract_data.length - 1].contracted_power ||
        (this.contract_data[this.contract_data.length - 1].plan > 0 &&
          !this.contract_data[this.contract_data.length - 1].tariff_groups[
            this.periods
          ].power_price == null)
      ) {
        this.show_section_2 = false;
      }
      if (
        this.contract_data[this.contract_data.length - 1].plan == 0 &&
        (this.contract_data[this.contract_data.length - 1].schedule_type ===
          null ||
          this.contract_data[this.contract_data.length - 1].schedule_type >= 3)
      ) {
        this.show_section_2 = false;
      }
    },

    get_last_tariff_group(historic_data: any): any {
      let last_bill = historic_data[0];
      let last_tariff_group =
        last_bill.tariff_groups[last_bill.tariff_groups.length - 1];
      return last_tariff_group;
    },
    //Convert tariff group in historic to the contract data format
    format_historic_tariff_group(tariff_group: any): any {
      let tg = cloneDeep(tariff_group);
      let tariff_plan = tg.tariff_plan;
      for (let i = 0; i < tariff_plan.length; i++) {
        tg[tariff_plan[i].name] = tariff_plan[i].energy;
        tg[tariff_plan[i].name.replace("energy", "grid")] = tariff_plan[i].grid;
      }
      delete tg.tariff_plan;
      return tg;
    },

    displayTariff(): void {
      this.stopLoadingShowModal("tariff-modal", true);
    },

    checkSubmitButton(): void {
      this.checkShowSection2();
    },
    replaceEmptyStringsWithNull(obj: any) {
      for (const key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          this.replaceEmptyStringsWithNull(obj[key]);
        } else if (obj[key] === "") {
          this.$set(obj, key, 0); // Use Vue's $set to ensure reactivity
        }
      }
    },

    async getContractData(): Promise<any> {
      if (this.condos_response.selected_role != "user") {
        await this.$store
          .dispatch("getContractData", {
            uri: "bill_data",
          })
          .then(async (resp: any) => {
            await this.$store.dispatch("updateBillValue", resp);
          });
      }
    },

    async addNewTariff(btn_name: string): Promise<any> {
      this.post = true;
      let submit_info = {
        uri: "bill_data",
        btn_name: btn_name,
      };
      let last_tariff_group =
        this.historic_data.length != 0
          ? this.get_last_tariff_group(this.historic_data)
          : null;
      let new_bill = cloneDeep(this.contract_data[0]);
      let temp_bill = null;
      //If its first bill
      if (this.historic_data.length == 0) {
        temp_bill = cloneDeep(this.contract_data[0]);
        temp_bill.tariff_groups.forEach((item: any) => {
          for (const it in item) {
            if (item[it] == null || undefined) {
              item[it] = 0;
            }
          }
        });
        // If the change date is null there is the need to remove the last period
      } else {
        // If tariffs did not change during the billing period
        if (this.dates_change == false) {
          temp_bill = new_bill;
          temp_bill.tariff_groups = [
            this.format_historic_tariff_group(last_tariff_group),
          ];
          temp_bill.tariff_change_date = null;
        }
        // If dates changed
        else {
          // If the date when tariffs changed is the same as the bp start date, only one period is actually added
          if (new_bill.start_date == new_bill.tariff_change_date) {
            // Make tariff_change_date null because it's only one period
            temp_bill = new_bill;
            temp_bill.tariff_change_date = null;
            temp_bill.tariff_groups = [temp_bill.tariff_groups[0]];
          }

          // If two periods are added
          else {
            temp_bill = new_bill;
            temp_bill.tariff_groups = [temp_bill.tariff_groups[0]];
            // Add as the first period, the last period of the previous bill

            temp_bill.tariff_groups.unshift(
              this.format_historic_tariff_group(last_tariff_group)
            );
          }
        }
      }
      let data_send = cloneDeep(this.contract_data[0]);
      data_send.tariff_groups = this.contract_data[0].tariff_groups;
      const result = data_send.tariff_groups.filter(
        (tariff: any) =>
          new Date(tariff.start_date) >=
            new Date(this.contract_data[0].bp_start_date) &&
          new Date(tariff.end_date) <=
            new Date(this.contract_data[0].bp_end_date)
      );
      data_send.tariff_groups = result;
      // if (this.contract_data[0].regulated_market == true) {
      //   this.$store.commit("removeGridPowerContract", this.period);
      // }

      let post_data = { ...submit_info, ...this.contract_data[0] };
      const post_data_send = cloneDeep(post_data);
      this.transformDateProperties(post_data_send);

      if (this.contract_data[0].tariff_groups.length == this.numberTariffs) {
        this.replaceEmptyStringsWithNull(post_data_send);
        return this.$store
          .dispatch("sendPost", post_data_send)
          .then(async (resp: any) => {
              if (resp && resp.tariff_plan !== undefined) {
                this.$store.commit("updateState", { contract_data: resp });
                await this.$store.dispatch("updateBillValue", resp);
              }
              this.stopLoadingShowModal(btn_name, resp ? true : false);
              this.$store.dispatch("getBillingPeriods").then(() => {
                this.getConsumptionData(
                  this.billing_periods
                    ? this.billing_periods[this.selected_index].start_date
                    : null,
                  this.billing_periods
                    ? this.billing_periods[this.selected_index].end_date
                    : null
                );
              });
              if(resp!=false){
                this.$store.dispatch("activateSnackBar");//aqui
              }
        });
      } else {
        return this.$store.dispatch("toggleSnackBar", {
          show: true,
          color: "red",
          message: "Insira o número correto de Períodos",
        });
      }
    },
  },

});


</script>

<style scoped lang="scss">
.section {
  width: 100%;
}

.sign {
  background-color: #47aea5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.filled {
  min-width: 120px;
  height: 30px;
  border-radius: 5px;
  background-color: #47aea5;
  color: #fff;
  font-weight: bold;
}

.not-filled {
  width: 166.5px;
  height: 48px;
  border-radius: 5px;
  border: solid 1px #47aea5;
  font-weight: bold;
  color: #47aea5;
}

.sign {
  background-color: #47aea5;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  .minus-sign {
    stroke: #fff;
  }
}

@keyframes rotate90 {
  to {
    transform: rotate(90deg);
  }
}

@keyframes Nrotate90 {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.disabled-text {
  color: #b1b1b1 !important;
}

.revert {
  animation-name: Nrotate90;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.rotated {
  animation-name: rotate90;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.sign:disabled {
  background-color: #d8d8d8;

  .minus-sign {
    stroke: #b1b1b1;
  }
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  background-color: #2f2f2f;
  color: #fff;
  border-radius: 6px;
  z-index: 1;
  transition: opacity 0.6s;
  font-size: 13px;
  padding: 9px;
  opacity: 0.9 !important;
}
.tooltip-bottom {
  top: 135%;
  left: 0;
}
.tooltip-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2f2f2f transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.v-application .info--text {
  color: #4d5460 !important;
  caret-color: #4d5460 !important;
}
.v-tooltip__content {
  visibility: hidden;
}
</style>